// LIBs
import { useState, useEffect } from "react";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";
import Grid from "../../components/Grid";

const FormMailTags = (props) => {
  const [ name, setName ] = useState("");
  const [ url, setUrl ] = useState("");

  useEffect(() => {
    if (props.data) {
      setName(props.data.name);
      setUrl(props.data.url);
    } else {
      clear();
    }
  }, [props.data])

  function send() {
    if (name.trim() && url.trim()) {
      props.onSend(name, url);
    }
  }

  function clear() {
    setName("");
    setUrl("");
  }

  return (
    <div className="FormMailTags">
      <div className="FormMailTags__inputs">
        <Grid>
          <Input 
            label="Nome: *" 
            className="InputDuo" 
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <Input 
            label="URL: *" 
            className="InputDuo" 
            value={url}
            onChange={event => setUrl(event.target.value)}
          />
        </Grid>
      </div>

      <div className="FormMailTags__buttons">
        <Button type="button" onClick={send}>Adicionar nova Tag</Button>
        { props.data &&
          <Button type="button" onClick={props.onCancel} border="outlined">Cancelar</Button>
        }
      </div>

      <div className="FormMailTags__content">
        {props.children}
      </div>
    </div>
  )
}

export default FormMailTags;