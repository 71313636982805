// LIBs
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// HELPERs
import navItemActive from "../../../core/helpers/navItemActive";

// COMPONENTs
import NavItem from "../../../components/NavItem";

// ICONs
import * as Icons from "react-icons/fi";

// SELECTORs
import { isActiveUser } from "../../../core/reducers/UserReducer/selectors";
import { listAbas } from "../../../core/reducers/AbasReducer/selectors";
import { allConfigApp } from "../../../core/reducers/AppReducer/selectors";

import logo from "../../../assets/account/logo_account.png";

const Header = (props) => {
  useEffect(navItemActive, [props.abas]);

  return (
    <header className="Header">
      <div className="Header__top">
        <div className="Header__top--container" onClick={navItemActive}>
          <Link to="/">
            <img src={props.allConfig.logo} width="100%" alt="" />
          </Link>
        </div>
      </div>

      <div className="Header__bottom">
        <div className="Header__bottom--container">
          <nav className="Navigation">
            <ul className="Navigation__menu">
              {
                props.abas &&
                props.abas.map((el, index) => {
                  const Icon = Icons[el.icon];

                  // Verificar se o usuário está ativo
                  if (props.is_active && el.status) {
                    return (
                      <NavItem
                        to={`/${el.link}`}
                        label={el.name}
                        icon={<Icon />}
                        key={index}
                      />
                    );
                  }
                  // usuario inativo retornar apenas a aba financial
                  if (props.is_active === 0 && el.link === 'financial') {
                    return (
                      <NavItem
                        to={`/${el.link}`}
                        label={el.name}
                        icon={<Icon />}
                        key={index}
                      />
                    );
                  }
                })
              }
            </ul>
          </nav>

          <div className={`More${props.showMore ? " active-arrow" : ""}`}>
            <button onClick={() => props.setShowMore(!props.showMore)}><Icons.FiChevronDown /></button>
          </div>

          <div className="Version">
            <a href="https://www.mdsystemweb.com.br/" target="_blank"><img src={logo} width="100%" alt="" /></a>
            <p>v1.0</p>
          </div>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = state => ({
  abas: listAbas(state),
  allConfig: allConfigApp(state),
  is_active: isActiveUser(state),
});

export default connect(mapStateToProps)(Header);