// LIBs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";
import Table from "../../components/Table";

// CONFIG
import { registerGroup, updateGroup, deleteGroup, getGroups, getGroupsAbas } from '../../core/api';

// UTILs
import toast from "../../core/helpers/toast";

// REDUCERs
import { listAbas } from "../../core/reducers/AbasReducer/selectors";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Nome", name: "name" },
];

const INITIAL_BLOCK = {
  list: [],
  count_pages: 1,
  current_page: 1,
};

const FormGroups = (props) => {
  let groupsButtonClass = "FormGroups__button";

  const [ idGroup, setIdGroup ] = useState(null);
  const [ name, setName ] = useState("");
  const [ rows, setRows ] = useState(INITIAL_BLOCK);
  const [ abas, setAbas ] = useState([]);
  const [ all, setAll ] = useState(false);
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    getList();
  }, [pagePerView, orderTableBy, order]);

  useEffect(() => {
    if (abas.length > 0) {
      let isAllChecked = abas.map(e => e.checked).reduce((a, b) => !(!a || !b));
      
      if (!isAllChecked) {
        setAll(false);
      } else {
        setAll(true);
      }
    }
  }, [abas])

  if (idGroup) groupsButtonClass += " grid-groups";

  async function getList(group_id = '', page = 1) {
    let reqA = await getGroups({
      page:page, 
      limit:pagePerView, 
      orderby:orderTableBy,
      order:order
    });

    if(reqA.data.list) {
      setRows(reqA.data);
    }

    if(props.abas.length > 0) {
      let newAbas = props.abas.map((el) => ({
        ...el,
        checked: false,
      }));

      if (group_id) {
        let reqAbas = await getGroupsAbas(group_id);

        reqAbas.data.forEach(item => {
          newAbas.find(aba => aba.id === item.aba_id).checked = true;
        })
      }

      setAbas(newAbas);
      props.onLoad();
    }
  }

  function fillDataWebStore(group) {
    setIdGroup(group.id);
    setName(group.name);
    getList(group.id, rows.current_page);
  }

  function send() {
    if (idGroup) {
      sendUpdate();
    } else {
      sendRegister();
    }
  }

  async function sendUpdate() {
    let newAbas = [ ...abas ];

    newAbas = newAbas.filter(item => item.checked);

    if (idGroup && newAbas.length > 0 && name) {
      let params = { name, abas: [] };
      params.abas = newAbas.map(item => ({ aba_id: item.id }));

      const req = await updateGroup(idGroup, params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.id) {
        getList("", rows.current_page);
        clear();
        setTimeout(() => {
          props.onLoad();
        }, 100)
      }
    }
  }

  /**
   * register
   */
  async function sendRegister() {
    let newAbas = [ ...abas ];

    newAbas = newAbas.filter(item => item.checked);

    if (name && newAbas.length > 0) {
      let params = { name, abas: [] };

      params.abas = newAbas.map(item => ({ aba_id: item.id }));

      const req = await registerGroup(params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.id) {
        toast("success", req.data.title);
        getList("", rows.current_page);
        clear();
        setTimeout(() => {
          props.onLoad();
        }, 100)
      }
    } else {
      toast("warning", "Preencha todas as informações corretamente!");
    }
  }

  /**
   * delete
   * @param {*} group 
   */
  async function del(group) {
    if (group.id) {
      const req = await deleteGroup(group.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.title) {
        toast("success", req.data.title);
        if (rows.list.length > 1) {
          getList("", rows.current_page);
        } else {
          getList();
        }
        clear();
        setTimeout(() => {
          props.onLoad();
        }, 100)
      }
    }
  }

  function clear() {
    setIdGroup(null);
    setName("");
  }

  function checkAll() {
    const newAbas = [ ...abas ];

    if (!all) {
      newAbas.map(item => item.checked = true);
    } else {
      newAbas.map(item => item.checked = false);
    }

    setAbas(newAbas);
    setAll(!all);
  }

  function checkedAba(e, id) {
    let newAbas = [ ...abas ];

    newAbas.find(item => item.id === id).checked = e.target.checked;

    setAbas(newAbas);
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  return (
    <div className="FormGroups">
      <div className="FormGroups__inputs">
        <div className="FormGroups__inputs--duo">
          <Input 
            type="text"
            className="InputDuo"
            label="Titulo: *"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      <div className="FormGroups__abas">
        <span className="FormGroups__abas--title">Selecionar Abas: *</span>

        <Input 
          type="checkbox"
          label="Todos"
          checked={all}
          onChange={checkAll}
        />

        <div className="FormGroups__abas--list">
          { (abas && abas.length > 0) &&
            abas.map((element, index) => (
              <Input 
                type="checkbox"
                label={element.name}
                checked={element.checked}
                onChange={(e) => checkedAba(e, element.id)}
                key={index}
              />
            ))
          }
        </div>
      </div>

      <div className={groupsButtonClass}>
        <Button type="button" onClick={send}>
          { idGroup ? 
            "Salvar"
            :
            "Registrar"
          }
        </Button>
        { idGroup &&
          <Button 
            type="button" 
            border="outlined"
            onClick={() => {
              clear();
              getList();
            }}
          >
            Cancelar
          </Button>
        }
      </div>

      <div className="FormGroups__list">
        <div className="TableArea">
          <Table 
            onFilter={(filter)=>{
              if (filter === orderTableBy) {
                switchOrder();
              } else {
                setOrder("asc");
                setOrderTableBy(filter);
              }
            }}
            filter={orderTableBy}
            order={order}
            setPageQty={(e)=>setPagePerView(+e)}
            collumns={collumns}
            rows={rows.list}
            navigation={true}
            countPages={rows.count_pages}
            currentPage={rows.current_page}
            onEdit={fillDataWebStore}
            onDel={del}
            onPage={page => getList(idGroup, page)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  abas: listAbas(state),
})

export default connect(mapStateToProps)(FormGroups);