// LIBs
import React, { useState, useEffect } from "react";
import ModalImage from "react-modal-image";
import { connect } from "react-redux";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";
import Table from "../../components/Table";
import Select from "../../components/Select";

// CONFIG
import {
    registerGallery,
    deleteGallery,
    getGallery,
    getWebStoresAll,
    updateGallery,
} from "../../core/api";

// UTILs
import toast from "../../core/helpers/toast";

// REDUCERs
import { listWebstore } from "../../core/reducers/WebstoreReducer/selectors"; 

const collumns = [
    { label: "ID", name: "id" },
    { label: "Imagem", name: "image" },
    { label: "Nome", name: "name" },
    { label: "Webstore", name: "webstore_name" },
];

const INITIAL_BLOCK = {
    list: [],
    count_pages: 1,
    current_page: 1,
};

const FormGallery = (props) => {
	const [file, setFile] = useState(null);
	const [rows, setRows] = useState(INITIAL_BLOCK);
	const [pagePerView, setPagePerView] = useState(3);
	const [orderTableBy, setOrderTableBy] = useState("id");
	const [imageRegisterWebstore, setImageRegisterWebstore] = useState("null");
	const [edit, setEdit] = useState(false);
	const [fileToEdit, setFileToEdit] = useState();
	const [webstore, setWebstore] = useState(0);
	const [ order, setOrder ] = useState("asc");

	useEffect(() => {
		const webstoreValue = +props.webstore.value;
		getList(1, webstoreValue);
		setWebstore(webstoreValue)
	}, [pagePerView, orderTableBy, order, props.webstore.value]);

	async function getList(page = 1, selectedWebstore = webstore) {
		const req = await getGallery({
			page: page,
			limit: pagePerView,
			orderby: orderTableBy,
			order:order,
			webstore_id: selectedWebstore,
		});

		if (props.list.length > 0) {
			setImageRegisterWebstore(props.list[0].id);
		}

		let data = { ...req.data };

		data.list.map((el, key) => {
			data.list[key].webstore_name = el.webstore ? el.webstore.name : "Todas Webstores";
			data.list[key].image = (
				<ModalImage
					small={el.image}
					large={el.image}
					alt={el.name}
					className="FormGallery__list--image"
				/>
			);
		});

		setRows(data);
	}

	async function send() {
		if (!file) {
			toast("warning", "Atualize a pagina ou escolha outra imagem!");
			return;
		}

		let formData = new FormData();
		formData.append("image", file);

		if (Number(imageRegisterWebstore)) {
			formData.append("webstore_id", imageRegisterWebstore);
		}

		let req = await registerGallery(formData);

		if (req.error.title) {
			toast("error", req.error.title);
			return;
		}

		toast("success", "Registrado com sucesso!");

		clear();
		getList();
	}

	/**
	 * delete
	 * @param {*} image
	 */
	async function del(image) {
		if (image.id) {
			const req = await deleteGallery(image.id);

			if (req.error && req.error.title) {
				toast("error", req.error.title);
				return;
			}

			toast("success", req.data.title);
			if (rows.list.length > 1) {
				getList(rows.current_page);
			} else {
				getList();
			}
			setTimeout(() => {
				props.onLoad();
			}, 100);
		}
	}

	function clear() {
		setEdit(false);
		setFile(null);
		setImageRegisterWebstore("null");
	}

	async function editImage() {
		if (file && edit && fileToEdit) {
			let formData = new FormData();

			if (typeof file != "string") {
				formData.append("image", file);
			}

			if (Number(imageRegisterWebstore)) {
				formData.append("webstore_id", imageRegisterWebstore);
			}

			formData.append("_method", "PUT");

			const req = await updateGallery(fileToEdit.id, formData);

			if (req.error && req.error.title) {
				toast("error", req.error.title);
				return;
			}

			toast("success", "Imagem editada com sucesso");
			if (rows.list.length > 1) {
				getList(rows.current_page);
			} else {
				getList();
			}
		}
		setTimeout(() => {
			clear();
			props.onLoad();
		}, 100);
	}

	function switchOrder (){
		setOrder(prev=> prev === "asc" ? "desc" : "asc")
	}

	return (
		<div className="FormGallery">
			<div className="FormGallery__inputs">
				<div className="FormGallery__inputs--duo">
					<Input
						type="file"
						className="InputDuo"
						label={`${edit ? "Alterar" : "Registrar"} Imagem:`}
						onChangeFile={(e) => setFile(e.target.files[0])}
						file={file}
					/>
					<Select
						value={imageRegisterWebstore}
						onChange={(e) => setImageRegisterWebstore(e.target.value)}
					>
						{props.list.map((web) => (
							<option value={web.id} key={web.id}>
								{web.name}
							</option>
						))}
					</Select>
				</div>
			</div>

			<div className="FormGallery__button">
				{!edit ? (
					<Button type="button" onClick={send}>
						Registrar
					</Button>
				) : (
					<>
						<Button type="button" onClick={() => editImage()}>
							Editar
						</Button>
						<Button border="outlined" type="button" onClick={() => clear()}>
							Cancelar
						</Button>
					</>
				)}
			</div>

			<div className="FormGallery__list">
				<div className="TableArea">
					<Table
						onFilter={(filter)=>{
							if (filter === orderTableBy) {
								switchOrder();
							} else {
								setOrder("asc");
								setOrderTableBy(filter);
							}
						}}
						filter={orderTableBy}
						order={order}
						setPageQty={(e) => setPagePerView(+e)}
						collumns={collumns}
						rows={rows.list}
						navigation={true}
						countPages={rows.count_pages}
						currentPage={rows.current_page}
						onDel={del}
						onEdit={(img) => {
							setImageRegisterWebstore(img.webstore_id || "null");
							setFile(img.image.props.small);
							setFileToEdit(img);
							setEdit(true);
						}}
						onPage={(page) => getList(page)}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({ 
	webstore: state.webstore,
	list: listWebstore(state),
});

export default connect(mapStateToProps)(FormGallery);
