// LIBs
import React from "react";

// COMPONENTs
import Table from "../../../components/Table";

// FORMs
import FormUserUser from "../../../Forms/Users/User";

const collumns = [
  { name: "id", label: "ID" },
  { name: "firstname", label: "Nome" },
  { name: "email", label: "E-mail", minCarac: 16 },
  { name: "is_active", label: "Status", boolean: true },
  { name: "group", label: "Função" },
];

export default props => (
  <div className="Users">
    <FormUserUser
      data={props.user}
      setUser={props.setUser}
      onRequest={props.onRequest}
      userCurrent={props.userCurrent}>
      {(props.data && props.data.list && props.data.list.length > 0) &&
        <Table
          onFilter={props.onFilter}
          filter={props.filter}
          order={props.order}
          setPageQty={props.setPageQty}
          collumns={collumns}
          rows={props.data.list}
          navigation={true}
          countPages={props.data.count_pages}
          currentPage={props.data.current_page}
          onEdit={(row) => props.onEdit(row)}
          onPage={props.onPage}
        />
      }
    </FormUserUser>
  </div>
);