//LIBs
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

//API
import { getPlans } from "../../core/api";

//CONTAINERs
import PlansMainPage from "../../containers/Plans/Main";

//CONTAINERs
import Register from "../../containers/Plans/Register";
import LayoutWelcome from "../../components/LayoutWelcome";

const RegisterCustomer = (props) => {
  const [plans, setPlans] = useState([]);
  const [step, setStep] = useState("select-plan");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    props.onShownLoad()
    init();
  }, []);

  async function init() {
    const plans = await getPlans();
    plans.data.customer.sort((a, b) => a.price - b.price);
    setPlans(plans.data.customer);
    props.onHideLoad()
  }

  const onLogin = () => {
    navigate("/account");
  };

  return (
    <div className="plans-area">
      <LayoutWelcome>
        {step === "select-plan" && (
          <div className="select-plans-container">
            <h1 className="select-plans-title">Selecione um plano:</h1>
            <PlansMainPage
              plans={plans}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              setStep={setStep}
              setSelectedPeriod={setSelectedPeriod}
            />

            <div className="select-plans-change-page">
              <p>
                Já possui uma conta? <span onClick={onLogin}>Clique Aqui!</span>
              </p>
            </div>
          </div>
        )}
        {step === "register" && (
          <Register
            onLogin={onLogin}
            onChangePlan={() => {
              setModalOpen(false);
              setSelectedPlan({});
              setStep("select-plan");
            }}
            selectedPlan={{
              plan_id: selectedPlan.id,
              period: selectedPeriod.period,
              price: selectedPlan.price,
              amount: selectedPeriod.price,
            }}
          />
        )}
      </LayoutWelcome>
    </div>
  );
};

export default RegisterCustomer;
