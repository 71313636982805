// LIBs
import { encode, decode } from "js-base64";

// REDUCER
import * as types from "./types";

const abasName = "@pageapi:abas";

const INITIAL_STATE = {
  list: [],
};

const abas = localStorage.getItem(abasName);

function AbasReducer(state = INITIAL_STATE, action) {
  let newState = { ...state };

  if (abas) {
    newState.list = JSON.parse(decode(abas));
  }
  
  switch(action.type) {
    case types.CHANGE_ABAS:
      newState.list = [ ...action.payload.data ];
      localStorage.setItem(abasName, encode(JSON.stringify(action.payload.data)));
      return newState;
    case types.CLEAR_ABAS:
      newState = INITIAL_STATE;
      localStorage.removeItem(abasName);
      return newState;
    default:
      return newState;
  }
}

export default AbasReducer;