// REDUCER
import * as types from "./types";

// API
import { getUser } from "../../api";

export const changeUser = async () => {
  const req = await getUser();

  return { type: types.CHANGE_USER, payload: req };
}

export const setUser = (user) => ({
  type: types.CHANGE_USER,
  payload: {
    data: { ...user },
  },
})

export const clearUser = () => ({
  type: types.CLEAR_USER,
});