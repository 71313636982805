// LIBs
import React, { useState, useEffect } from "react";

// CONTAINERs
import Details from "../../containers/Home/Details";


// CONFIG
import { getHome } from '../../core/api';

const HomeView = (props) => {  
  const [data, setData] = useState([]);

  useEffect(() => {
      getList();
    }, []);
    
  async function getList() {
    props.onShownLoad()
    let req = await getHome();
    
    if (req.data) {
      setData(req.data);
    }
    props.onHideLoad()
  }

  return (
    <main className="HomeView">
      <Details data={data} />
    </main>
  );
}

export default HomeView;
