// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// COMPONENTs
import Table from "../../../components/Table";
import TextField from "../../../components/TextField";

// UTILs
import toast from "../../../core/helpers/toast";

// CONFIG
import { orderEnableMP, getOrderStatus } from '../../../core/api';

let intervalObserver;

const collumns = [
  { label: "Fatura", name: "mp_id" },
  { label: "Data do Pagamento", name: "created" },
  { label: "Valor Total", name: "amount" },
  { label: "Plano", name: "plan" },
  { label: "Tipo", name: "type" },
];

const collumnsResult = [
  { label: "Descrição", name: "description" },
  { label: "Valor", name: "value" },
];

export default (props) => {
  const [order, setOrder] = useState();
  const [rowsResult, setRowsResult] = useState([]);

  // checkout
  const [qr_code_base64, setQrCode64] = useState();
  const [copy_qr_code, setCopyQrCode] = useState();
  const [statusOrder, setStatusOrder] = useState(false);

  useEffect(() => {
    if (order) {
      let params = [
        { description: `Plano: ${order.plan}`, value: order.amount },
        { description: "Qt WebStore", value: order.webstores_quantity },
        { description: "Sub Total", value: order.amount },
        { description: "Total", value: order.amount },
        { description: "", value: `Vencimento: ${order.created}` },
        {
          description: "Gerar PIX", value: (
            <button onClick={() => getPix(order.id)}>
              PIX
            </button>
          )
        },
      ];
      setRowsResult(params);
    }
  }, [order]);

  async function observer() {
    if (localStorage.getItem('order_id')) {
      intervalObserver = setInterval(async () => {
        const id = localStorage.getItem('order_id');
        let req = await getOrderStatus(id);

        if (req.data.status) {
          setStatusOrder(true);
          clearInterval(intervalObserver);
          localStorage.removeItem('order_id')
          toast("success", "Pagamento realizado com sucesso!");
          props.list();
          setQrCode64("");
          setOrder("");
        }
      }, 5000);
    }
  }

  async function getPix(id) {
    let req = await orderEnableMP(id);

    if (req.error.title) {
      toast("error", req.error.title);
      return;
    }

    if (req.data.result_mp) {
      window.localStorage.setItem('order_id', id);
      setQrCode64(req.data.result_mp.transaction_data.qr_code_base64);
      setCopyQrCode(req.data.result_mp.transaction_data.qr_code);
      observer();
    }
  }

  return (
    <div className="Pending">
      <Table
        collumns={collumns}
        rows={props.data}
        className="Pending__invoices"
        onView={(row) => setOrder(row)}
      />
      {order &&
        <Table
          id="table-pending-financial"
          collumns={collumnsResult}
          rows={rowsResult}
          className="Pending__results"
          hiddeNavigation={true}
        />
      }
      {qr_code_base64 &&
        <div className="payment">
          <p>Para efetuar o pagamento</p>
          <h4>Leia o QR Code ou Copie</h4>
          <img width="100%" src={`data:image/jpeg;base64,${qr_code_base64}`} alt="QR Code" />
          <TextField
            value={`${copy_qr_code}`}
            copy={true}
            private={false}
          />
        </div>
      }
    </div>
  );
}