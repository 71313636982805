// LIBs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// COMPONENTs
import Table from "../../../components/Table";

// CONFIG
import { getContactList, deleteContact } from '../../../core/api';

// UTILs
import toast from "../../../core/helpers/toast";
import scrollToPosition from "../../../core/helpers/scrollToTop";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Nome", name: "name" },
  { label: "Status", name: "is_visible" },
  { label: "Assunto", name: "subject" },
  { label: "Webstore", name: "webstore_name" },
];

const Responses = (props) => {
  const [rows, setRows] = useState([]);
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [webstore, setWebstore] = useState(0);
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    const webstoreValue = +props.webstore.value;
    getList(1, webstoreValue);
    setWebstore(webstoreValue)
  }, [pagePerView, orderTableBy, order, props.webstore.value]);

  useEffect(() => {
    if (props.response && rows && rows.list && rows.list.length) {
      let responseRow = rows.list.find(item => item.id === props.response.id);
      if (responseRow && responseRow.is_visible !== props.response.is_visible) {
        getList();
      }
    }
  }, [props.response]);

  async function getList(page = 1, webstoreFilter = webstore) {
    let req = await getContactList({
      page:page, 
      limit:pagePerView, 
      orderby: orderTableBy, 
      order:order,
      webstore_id:webstoreFilter
    });
    
    req.data.list = req.data.list.map((contact) => {
      contact.webstore_name = contact.webstore?.name || "Nenhuma"
      return contact
    })
    
    setRows(req.data);
    props.onLoad()
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  /**
   * delete
   * @param {*} param 
   */
  async function del(param) {
    if (param.id) {
      const req = await deleteContact(param.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
        return;
      }

      toast("success", req.data.title);
      if (rows.list.length > 1) {
        getList(rows.current_page);
      } else {
        getList();
      }
      setTimeout(() => {
        getList();
      }, 100);

    }
  }

  return (
    <div className="Response">
      <Table
        onFilter={(filter)=>{
            if (filter === orderTableBy) {
              switchOrder();
            } else {
              setOrder("asc");
              setOrderTableBy(filter);
            }
          }}
        filter={orderTableBy}
        order={order}
        setPageQty={(e)=>setPagePerView(+e)}
        collumns={collumns}
        rows={rows.list}
        onView={res => {
          props.onSelect(res);
          let scrollInterval = setInterval((()=>{
            if(document.querySelector(".Box__content")){
              scrollToPosition(".Box__content")
              clearInterval(scrollInterval)
            }
          }),500)
        }}
        onDel={del}
        onPage={page => getList(page)}
        countPages={rows.count_pages}
        currentPage={rows.current_page}
        navigation={true}
      />
    </div>
  );
}


const mapStateToProps = (state) => ({ webstore: state.webstore });

export default connect(mapStateToProps)(Responses);
