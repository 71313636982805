// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// API
import { 
  allBlocks,
  allContentBlock,
  addContentBlock,
  getGalleryAll,
  getWebStoresAll,
  getGalleryWebstore
} from "../../core/api";

// CONTAINERs
import List from "../../containers/Content/List";
import Item from "../../containers/Content/Item";
import Modal from "../../containers/Content/Modal";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// HELPERs
import toast from "../../core/helpers/toast";
import { validate } from "../../core/helpers/validate";

// ACTIONs
import scrollToPosition from "../../core/helpers/scrollToTop";

// REDUCERs
import { planUser } from "../../core/reducers/UserReducer/selectors";
import { listWebstore } from "../../core/reducers/WebstoreReducer/selectors";

const INITIAL_BLOCK = {
  list: [],
  count_pages: 1,
  current_page: 1,
};

const ContentView = (props) => {
  const navigate = useNavigate();
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);
  const [ blocks, setBlocks ] = useState(INITIAL_BLOCK);
  const [ images, setImages ] = useState([]);
  const [ showInputsBlocks, setShowInputsBlocks ] = useState({});
  const [ inputFile, setInputFile ] = useState(null);
  const [ contentBlock, setContentBlock ] = useState([]);
  const [ tab, setTab ] = useState(0);
  const [ planStatus, setPlanStatus ] = useState(true);
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [ webstore, setWebstore ] = useState(0);
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    const webstoreValue = +props.webstore.value
    setWebstore(webstoreValue)
    listBlocks(1, webstoreValue);
  }, [pagePerView, orderTableBy, order, props.webstore.value])

  useEffect(() => {
    props.onShownLoad();
    validate(navigate);
  }, [])
  
  useEffect(() => {
    listContentBlock();
    listImages();
  }, [showInputsBlocks]);

  useEffect(()=> {
    toggleDropdown("content");
  }, [contentBlock]);

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);
        
      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  async function listBlocks(page = 1, selectedWebstore = webstore) {
    const req = await allBlocks({
      page:page, 
      limit:pagePerView, 
      orderby:orderTableBy, 
      order:order,
      webstore_id: selectedWebstore 
    });

    if (req.data && req.data.list && req.data.list.length > 0) {
      setBlocks(req.data);
    } else {
      setBlocks(INITIAL_BLOCK);
    }

    if (props.plan) {
      const price = props.plan.price;
      const planStatus = price > 0;
      setPlanStatus(planStatus);
    }
    props.onHideLoad();
  }

  async function listImages() {
    if (showInputsBlocks.webstore_id) {
      const req = await getGalleryWebstore(showInputsBlocks.webstore_id);

      if (req.data && req.data && req.data.length > 0) {
        setImages(req.data);
      } else {
        setImages([]);
      }
    }
  }

  async function listContentBlock() {
    if (showInputsBlocks.id) {
      const req = await allContentBlock(showInputsBlocks.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.length > 0) {
        setContentBlock(req.data[0].items);
      } else {
        setContentBlock([]);
      }
    }
  }

  function viewBlock(block) {
    setShowInputsBlocks(block);
  }

  function addInputs() {
    const newContentBlock = [ ...contentBlock ];
    newContentBlock.push(JSON.parse(JSON.stringify(newContentBlock[0])));

    const contentBlockItem = [ ...newContentBlock[newContentBlock.length - 1] ];

    contentBlockItem.forEach(item => {
      item.name = `${item.name}_${newContentBlock.length - 1}`;
      item.value = "";

      if (item.type === "file") {
        item.file = null;
      } else if (item.type === "files") {
        item.file = [];
      }
    });

    if (planStatus) {
      setContentBlock(newContentBlock);
    } else {
      toast("warning", "Seu plano é gratuito e não permite fazer mais um registro!");
    }
  }

  function handle(text, contentItemId, contentId, contentItemValueId = null) {
    let obj = JSON.parse(JSON.stringify(contentBlock));
    
    if (
      (
        obj[contentId][contentItemId].type === "text" ||
        obj[contentId][contentItemId].type === "textarea" ||
        obj[contentId][contentItemId].type === "number"
      ) && 
      contentItemValueId === null
    ) {
      obj[contentId][contentItemId].value = text;
    } else {
      obj[contentId][contentItemId].values[contentItemValueId] = text;
    }

    setContentBlock(obj);
  }

  function hangleImage(el) {
    let obj = JSON.parse(JSON.stringify(contentBlock));
    
    if (obj[tab][inputFile].type === "file") {
      obj[tab][inputFile].value = el.name;
      obj[tab][inputFile].file = el.image;
    } else {
      let file = [ ...obj[tab][inputFile].file ];
      file.push({ value: el.name, file: el.image })
      obj[tab][inputFile].file = file;
    }
    
    setContentBlock(obj);
    setInputFile(null);
  }


  function handleDelImageGroup(index, key) {
    let obj = JSON.parse(JSON.stringify(contentBlock));
    let file = [ ...obj[tab][index].file ];

    if (file[key]) {
      file = file.filter((el, index) => index !== key);
      obj[tab][index].file = file;
    }

    setContentBlock(obj);
  }

  function handleAddInputGroup(index, contentId) {
    let obj = JSON.parse(JSON.stringify(contentBlock));
    obj[contentId][index].values.push("");
    setContentBlock(obj);
  }

  function handleDelInputGroup(index, contentId, keyValue) {
    let obj = JSON.parse(JSON.stringify(contentBlock));
    let values = [ ...obj[contentId][index].values ];

    if (values[keyValue] !== undefined && keyValue > 0) {
      values = values.filter((el, index) => index !== keyValue);
      obj[contentId][index].values = values;
    }

    setContentBlock(obj);
  }

  function handleTextArea(contentItemId, contentId) {
    const newContentBlock = JSON.parse(JSON.stringify(contentBlock));
    newContentBlock[contentId][contentItemId].editor = !newContentBlock[contentId][contentItemId].editor;
    setContentBlock(newContentBlock);
  }

  function deleteInputs() {
    if (contentBlock.length > 1) {
      const newContentBlock = contentBlock.filter((item, key) => key !== tab);

      newContentBlock.forEach((item, key) => {
        item.forEach(input => {
          input.name = `${input.name.substring(0, input.name.length - 2)}${key === 0 ? "" : `_${key}`}`;
        });
      });

      setContentBlock(newContentBlock);
      setTab(tab - 1);
    }
  }

  async function send() {
    if (contentBlock) {
      if (showInputsBlocks.id) {
        const req = await addContentBlock(showInputsBlocks.id, { items: contentBlock });
  
        if (req.error && req.error.title) {
          toast("error", req.error.title);
        }

        if (req.data) {
          toast("success", "Atualizado com sucesso!");
        }
      } 
    }
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }
  
  return (
    <main className="ContentView">
      <DropdownArea
        label="Lista de Conteúdo" 
        id="list-content" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="ContentView__list"
      >
        <List 
          onFilter={(filter)=>{
              if (filter === orderTableBy) {
                switchOrder();
              } else {
                setOrder("asc");
                setOrderTableBy(filter);
              }
            }}
          filter={orderTableBy}
          order={order}
          setPageQty={(e)=>setPagePerView(+e)}
          blocks={blocks} 
          onView={(data)=>{
            viewBlock(data);
            setActiveId("content")
            let scrollInterval = setInterval((()=>{
              if(document.querySelector("#content")){
                scrollToPosition("#content")
                clearInterval(scrollInterval)
              }
            }),500)
          }} 
          onPage={page => listBlocks(page)}
        />
      </DropdownArea>

      { contentBlock.length > 0 &&
        <DropdownArea
          label="Conteúdo" 
          id="content" 
          activeId={activeId}
          height={height}
          onShow={isDropdownArea}
          className="ContentView__content"
        >
          <Item 
            block={showInputsBlocks}
            data={contentBlock}
            tab={tab}
            onSelect={(key) => setTab(key)}
            onAdd={addInputs}
            onChange={handle}
            onChangeFile={(key) => setInputFile(key.toString())}
            onDelFileGroup={handleDelImageGroup}
            onAddTextsGroup={handleAddInputGroup}
            onDelTextsGroup={handleDelInputGroup}
            onToggleTextArea={handleTextArea}
            onDel={deleteInputs}
            onSend={send}
          />
        </DropdownArea>
      }

      <Modal 
        webstoreList={props.list}
        data={images} 
        visible={!!inputFile} 
        onHide={() => setInputFile(null)}
        onChangeFile={hangleImage}
        onLoad={listImages}
      />
    </main>
  );
}

const mapStateToProps = state => ({
  webstore:state.webstore,
  plan: planUser(state),
  list: listWebstore(state),
});

export default connect(mapStateToProps)(ContentView);
