// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import Table from "../../../components/Table";

// APIs
import { getAbasApi } from "../../../core/api";

const INITIAL_BLOCK = {
  list: [],
  count_pages: 0,
  current_page: 0,
}

const FormApiGenerateApi = (props) => {
  const [ rows, setRows ] = useState(INITIAL_BLOCK);
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [ order, setOrder ] = useState("asc");

  useEffect(() => {
    getAbas();
  }, [pagePerView, orderTableBy, order]);

  async function getAbas(page = 1) {
    let req = await getAbasApi({
      page:page, 
      limit:pagePerView, 
      orderby:orderTableBy,
      order:order
    });
    setRows(req.data);
    
    props.onHideLoad()
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  const collumns = [
    { label: "ID", name: "id" },
    { label: "Título", name: "title" },
    { label: "Rota", name: "route", copy: true, minCarac: 1000 },
  ];

  return (
    <div className="FormApiGenerateApi">
      <div className="FormApiGenerateApi__content">
        <Table 
          onFilter={(filter)=>{
            if (filter === orderTableBy) {
              switchOrder();
            } else {
              setOrder("asc");
              setOrderTableBy(filter);
            }
          }}
          filter={orderTableBy}
          order={order}
          setPageQty={(e)=>setPagePerView(+e)}
          collumns={collumns}
          rows={rows.list}
          navigation={true}
          countPages={rows.count_pages}
          currentPage={rows.current_page}
          onPage={page => getAbas(page)}
        />
      </div>
    </div>
  );
}

export default FormApiGenerateApi;