// LIBs
import React from "react";

// COMPONENTs
import Button from "../../../components/Button";

export default props => (
  <div className="WelcomeContainer">
    <div className="WelcomeContainer__content">
      <div className="WelcomeContainer__content--title">
        <h1>Faça seu login</h1>
      </div>

      <div className="WelcomeContainer__content--text">
        <p>Bem-vindo ao sistema api Front-End, para login e acesso escolha uma das opções abaixo.</p>
      </div>

      <div className="WelcomeContainer__content--buttons">
        <Button type="button" onClick={props.onLogin}>Login</Button>
        <Button type="button" onClick={props.onRegister}>Nova conta</Button>
      </div>
    </div>
  </div>
);