// LIBs
import React from "react";

// FORMs
import FormGallery from "../../Forms/Gallery";

export default props => (
  <div className="Gallery">
    <FormGallery onLoad={props.onRequest} />
  </div>
);