// LIBs
import { encode, decode } from "js-base64";

// REDUCER
import * as types from "./types";

const allConfigName = "@pageapi:all_config";

const INITIAL_STATE = {
  lang: localStorage.getItem(types.__mdDash_lang_app) || "pt-BR",
  mode: localStorage.getItem(types.__mdDash_mode_app) || "light",
  scrollTop: 0,
  screens: {
    screenWidth: 0,
    screenHeight: 0,
  },
  allConfig: {},
};

const allConfig = localStorage.getItem(allConfigName);

function AppReducer(state = INITIAL_STATE, action) {
  let newState = { ...state };

  if (allConfig) {
    newState.allConfig = JSON.parse(decode(localStorage.getItem(allConfigName)));
  }

  switch(action.type) {
    case types.UPDATE_LANG_APP:
      localStorage.setItem(types.__mdDash_lang_app, action.payload);
      return { ...newState, lang: action.payload };
    case types.TOGGLE_MODE_APP:
      localStorage.setItem(types.__mdDash_mode_app, newState === "light" ? "dark" : "light");
      return { ...newState, mode: newState === "light" ? "dark" : "light" };
    case types.UPDATE_SCROLL_TOP_APP:
      return { ...newState, scrollTop: action.payload };
    case types.UPDATE_SCREENS_APP:
      newState.screens.screenWidth = action.payload.screenWidth;
      newState.screens.screenHeight = action.payload.screenHeight;
      return newState;
    case types.SET_ALL_CONFIG:
      newState.allConfig = action.payload.data;
      localStorage.setItem(allConfigName, encode(JSON.stringify(action.payload.data)));
      return newState;
    default:
      return newState;
  }
}

export default AppReducer;
