// LIBs
import React, { cloneElement } from "react";

export default props => {
  function renderChildren() {
    if (props.children.length !== undefined) {
      const newChildren = [ ...props.children ];
      const editorWorld = newChildren.filter(el => !el.props.label);

      newChildren.forEach((el, index) => {
        if (
          !el.props.label &&
          (index !== 0 && !(index % 2 === 0))
        ) {
          if (newChildren[index - 1].props.className) {
            newChildren[index - 1] = cloneElement(newChildren[index - 1], { 
              className: newChildren[index - 1].props.className+" colspan-2",
              key: index - 1,
            });
          } else {
            newChildren[index - 1] = cloneElement(newChildren[index - 1], { 
              className: "colspan-2",
              key: index - 1,
            });
          }
        }

        if (((newChildren.length - 1) - editorWorld.length) % 2 === 0) {
          if (newChildren[newChildren.length - 1].props.className) {
            newChildren[newChildren.length - 1] = cloneElement(newChildren[newChildren.length - 1], { 
              className: newChildren[newChildren.length - 1].props.className+" colspan-2",
              key: newChildren.length - 1,
            });
          } else {
            newChildren[newChildren.length - 1] = cloneElement(newChildren[newChildren.length - 1], { 
              className: "colspan-2",
              key: newChildren.length - 1,
            });
          }
        }
      });

      return newChildren;
    } else {
      return props.children;
    }
  }
  
  return (
    <div className="GridArea">
      {renderChildren()}
    </div>
  );
}