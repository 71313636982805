// CORE
import { getUser } from '../../core/api';

export const validate = async (navigate) => {
    
    let req = await getUser();

    if (req.data) {
        if (!req.data.is_active) {
            navigate('/')
        }
    }
}