// LIBs
import React, { useEffect, useState } from "react";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import Pages from "../../containers/Pages/Pages";

const PagesView = (props) => {
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);

  useEffect(()=>{props.onHideLoad()},[])

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  return (
    <main className="PagesView">
      <DropdownArea
        label="Páginas" 
        id="pages" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="PagesView__blocks"
      >
        <Pages />
      </DropdownArea>
    </main>
  );
}

export default PagesView;