// LIBs
import React from "react";

// FORMs
import FormUserProfile from "../../../Forms/Users/Profile";

export default props => (
  <div className="Users">
    <FormUserProfile header={props.header} onRequest={props.onRequest} />
  </div>
);