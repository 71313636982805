// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";
import Modal from "../../components/Modal";

// CONTAINERs
import MailTags from "../../containers/MailTags/Tags";
import MailContacts from "../../containers/MailTags/Contacts";

const INITIAL_ROWS = [
  {
    id: Math.random() * 9999,
    name: "Instagram",
    contact: "Diogo, Lêvy",
    url: "www.instagram.com",
  },
  {
    id: Math.random() * 9999,
    name: "Site",
    contact: "Thiago",
    url: "www.mdsystemweb.com.br",
  },
  {
    id: Math.random() * 9999,
    name: "Produto",
    contact: "Thiago",
    url: "www.mdsystemweb.com.br/landingpage",
  },
  {
    id: Math.random() * 9999,
    name: "Md Dash",
    contact: "Douglas",
    url: "commerce.mdsystemweb.com.br",
  },
];

const names = [
  {
    id: Math.random() * 9999,
    name: "Diogo",
  },
  {
    id: Math.random() * 9999,
    name: "Lêvy",
  },
  {
    id: Math.random() * 9999,
    name: "Thiago",
  },
  {
    id: Math.random() * 9999,
    name: "Douglas",
  },
];

const MailTagsView = (props) => {
  const [ activeId, setActiveId ] = useState("");
  const [ data, setData ] = useState([]);
  const [ tagSelected, setTagSelected ] = useState(null);
  const [ tagContacts, setTagContacts ] = useState(null);
  const [ all, setAll ] = useState(false);

  useEffect(start, [])

  useEffect(() => {
    if (data.length > 0) {
      let isAllChecked = data.map(e => e.checked).reduce((a, b) => !(!a || !b));
      
      if (!isAllChecked) {
        setAll(false);
      } else {
        setAll(true);
      }
    }
    props.onHideLoad()
  }, [data])

  function start() {
    let newRows = INITIAL_ROWS.map(row => {
      row.checked = false;
      return row;
    });
    setData(newRows);
  }

  function checkAll() {
    const newData = [ ...data ];

    if (!all) {
      newData.forEach(item => item.checked = true);
    } else {
      newData.forEach(item => item.checked = false);
    }

    setData(newData);
    setAll(!all);
  }

  function toggleCheckedData(index) {
    let newData = [ ...data ];
    newData[index].checked = !newData[index].checked;
    setData(newData);
  }

  return (
    <main className="MailTagsView">
      <DropdownArea
        label="E-mail Tags" 
        id="mail-tags" 
        activeId={activeId}
        onShow={id => activeId !== id ? setActiveId(id) : setActiveId("")}
        className="MailTagsView__blocks"
      >
        <MailTags 
          data={data} 
          tagSelected={data[tagSelected]}
          allChecked={all} 
          onAllChecked={checkAll}
          onToggleChecked={toggleCheckedData} 
          onTagSelected={setTagSelected}
          onTagContact={setTagContacts}
        />
      </DropdownArea>

      <Modal visible={tagContacts !== null} title="Contatos" onHide={() => setTagContacts(null)}>
        <MailContacts data={names} tag={data[tagContacts]} />
      </Modal>
    </main>
  )
}

export default MailTagsView;