// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Input from "../../components/Input";
import EditorWorld from '../../components/EditorWorld';

// CONTAINERs
import Responses from "../../containers/Contact/Responses";
import Box from "../../containers/Contact/Box";

// CONFIG
import { registerContact, updateContact } from '../../core/api';

// UTILs
import toast from "../../core/helpers/toast";

// HELPERs
import { validate } from "../../core/helpers/validate";

const ContactView = (props) => {
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState("box-entry");
  const [response, setResponse] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    props.onShownLoad()
    validate(navigate);
  }, []);

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if (id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setActiveId(id);
      }
    } else {
      setActiveId("");
    }
  }

  async function handleClick() {
    let params = {
      subject:subject,
      message: message,
      contact_id: response.id
    };

    let req = await registerContact(params);

    if (req.error && req.error.title) {
      toast("error", req.error.title);
      return;
    }

    toast("success", "E-mail enviado com sucesso!");
    setVisibleModal(false);
    isVisible(response)
    setSubject('');
    setMessage('');
  }

  /**
   * update is_visible
   */
  async function isVisible(data) {
    let req = await updateContact(data.id);

    if (req.data && req.data.id) {
      setResponse(req.data);
    }
  }

  return (
    <main className="ContactView">
      <DropdownArea
        label="Caixa de Entrada"
        id="box-entry"
        activeId={activeId}
        onShow={isDropdownArea}
        className="ContactView__box"
      >
        <Responses onLoad={()=>props.onHideLoad()} onSelect={isVisible} response={response} />
        {response &&
          <Box data={response} onShowModal={() => setVisibleModal(true)} />
        }
      </DropdownArea>

      {response &&
        <Modal
          visible={visibleModal}
          title={`Resposta para: ${response.name}`}
          onHide={() => setVisibleModal(false)}
          className="ContactView__modal"
        >
          <Input
            type="text"
            label="Assunto"
            onChange={(e) => setSubject(e.target.value)} />

          <EditorWorld
            editor={false}
            value={message}
            onChange={(event) => setMessage(event)}
          />

          <Button type="button" onClick={handleClick}>Responder</Button>
        </Modal>
      }
    </main>
  );
}

export default ContactView;