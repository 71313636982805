//LIBs
import { useState } from 'react';
import { FiX } from "react-icons/fi";

// UTILs
import toast from '../../../core/helpers/toast';

//COMPONENTs
import Select from '../../../components/Select';
import Input from '../../../components/Input';

// API
import { registerGallery } from '../../../core/api';

export default (props) => {
  const [imageRegisterWebstore, setImageRegisterWebstore] = useState();
  let modalClass = "ModalContent";

  if (props.visible) modalClass += " active-modal";

  function hideModal(event) {
    if (event.target.classList.contains("ModalContent")) {
      props.onHide();
    }
  }

  async function registerImage(event) {
    if (event.target.files[0]) {
      const formData = new FormData();

      formData.append("image", event.target.files[0]);

      if(Number(imageRegisterWebstore)){
        formData.append("webstore_id", imageRegisterWebstore)
      }

      const req = await registerGallery(formData);
      setImageRegisterWebstore("null")

      if (req.error.title) {
        toast('error', req.error.title);
        return;
      }

      props.onLoad();
    } else {
      toast("warning", "Atualize a pagina ou escolha outra imagem!");
    }
  }

  return (
    <div className={modalClass} onClick={hideModal}>
      <div className="ModalContent__area">
        <div className="ModalContent__area--container">
          <div className="Close">
            <button onClick={props.onHide}>
              <FiX size={32} color="#777777"/>
            </button>
          </div>

          <div className="Options">
            <div className="Options__title">
              <h3>Galeria</h3>
            </div>

            <div className="Options__buttons">
              <Input label="Importar Imagem para" type="file" onChangeFile={registerImage} />
              <Select 
                value={imageRegisterWebstore} 
                onChange={(e)=>setImageRegisterWebstore(e.target.value)}
              >
                <option value="null">Todas Webstores</option>
                { props.webstoreList.map(web=>(
                    <option 
                      value={web.id} 
                      key={web.id}
                    >
                        {web.name}
                    </option>
                  ))
                }
              </Select>
            </div>
          </div>

          <div className="ImagesList">
            { (props.data && props.data.length > 0) &&
              props.data.map((el, index) => (
                <div 
                  onClick={() => props.onChangeFile(el)}
                  className="Image" 
                  key={index}
                >
                  <img src={el.image} alt="" />
                  <p>{el.name}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}