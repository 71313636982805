// REDUCER
import * as types from "./types";

// API
import { getAbas } from "../../api";

export const changeAbas = async () => {
  const req = await getAbas();

  return { type: types.CHANGE_ABAS, payload: req };
}

export const clearUser = () => ({
  type: types.CLEAR_ABAS,
});