// LIBs
import React, { useState, useEffect } from "react";

// COMPONENTs
import TextField from "../../../components/TextField";
import Button from "../../../components/Button";

export default (props) => {
  return (
    <div className="Box">
      <div className="Box__content">
        <div className="Box__content--details">
          <div className="Title">
            <h3>{props.data.subject ?? ""}</h3>
          </div>

          <div className="Body">
            <p>{props.data.message ?? ""}</p>
          </div>

          <div className="Info">
            <TextField label="Nome:" value={props.data.name ?? ""} />
            <TextField label="Email:" value={props.data.email ?? ""} />
          </div>

          {props.data.child && props.data.child.map((el, key) => {
            return (
              <div className="Respost" key={key}>
                <hr />
                <p className="Respost__Title">Resposta</p>

                <div className="data">
                  <div className="Title">
                    <h3>{el.subject ?? ""}</h3>
                  </div>
                  <div className="Body">
                    <div dangerouslySetInnerHTML={{ __html: el.message }} />
                  </div>
                  <div className="Info">
                    <TextField label="Nome:" value={props.data.name ?? ""} />
                    <TextField label="Email:" value={props.data.email ?? ""} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="Box__content--button">
          <Button type="button" onClick={props.onShowModal}>Responder</Button>
        </div>
      </div>
    </div>
  );
}