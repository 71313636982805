// LIBs
import React, { useState, useEffect } from "react";

export default (props) => {
  const [ navigationActive, setNavigationActive ] = useState("");

  useEffect(() => {
    if (props.navigation && props.navigation.length > 0) {
      if (props.activeNavigation && props.navigation[props.activeNavigation] !== undefined) {
        setNavigationActive(props.navigation[props.activeNavigation].name || "");
      } else {
        setNavigationActive(props.navigation[0].name || "");
      }
    }
  }, [])

  return (
    <div className="BlockNav">
      <div className="BlockNav__navigation">
        <ul>
          { (props.navigation && props.navigation.length > 0) &&
            props.navigation.map((item, key) => (
              <li 
                className={item.name === navigationActive ? "active-block-nav" : ""} 
                onClick={() => setNavigationActive(item.name)}
                key={key}
              >
                {item.label}
              </li>
            ))
          }
        </ul>
      </div>

      <div className="BlockNav__body">
        { (props.blocks && props.blocks.length > 0) &&
          props.blocks.map((item, key) => {
            if (item.name === navigationActive) {
              return <React.Fragment key={key}>{item.block}</React.Fragment>;
            } else {
              return null
            }
          })
        }
      </div>
    </div>
  );
}