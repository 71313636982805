// LIBs
import React, { useEffect } from "react";

const NotFoundView = (props) => {
  useEffect(()=>{props.onHideLoad()},[])
  return (
    <main className="NotFounView">
      Not Found (404)
    </main>
  );
}

export default NotFoundView;