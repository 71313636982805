// LIBs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// ACTIONs
import { changeUser } from "../../core/reducers/UserReducer/actions";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";
import CancelSignature from "../../components/CancelSignature";

// CONTAINERs
import Profile from "../../containers/Users/Profile";

// REDUCERs
import { allUser } from "../../core/reducers/UserReducer/selectors";

const UsersView = (props) => {
	const [activeId, setActiveId] = useState("");
	const [height, setHeight] = useState(1);
	const [header, setHeader] = useState("");
	const [user, setUser] = useState([]);

	useEffect(() => {
		start();
	}, []);

	const start = async () => {
		props.onShownLoad()
		if (window.localStorage.getItem("token")) {
			let header = {
				headers: { Authorization: `Bearer ${window.localStorage.getItem("token")}` },
			};
			setHeader(header);
		}

		setUser(props.user);
		
		props.onHideLoad()
	};

	function isDropdownArea(id) {
		if (activeId === "") {
			toggleDropdown(id);
		} else {
			if (activeId !== id) {
				toggleDropdown(id);
			} else {
				toggleDropdown();
			}
		}
	}

	function toggleDropdown(id = "") {
		if (id !== "") {
			const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

			if (element !== null) {
				setHeight(element.clientHeight);
				setActiveId(id);
			}
		} else {
			setActiveId("");
			setHeight(1);
		}
	}

	return (
		<main className="UsersView">
			<DropdownArea
				label={`Meus Dados`}
				id="users"
				activeId={activeId}
				height={height}
				onShow={isDropdownArea}
				className="UsersView__blocks"
			>
				<Profile header={header} onRequest={props.changeUser} />
				{user.payload?.data?.user_id === null && <CancelSignature />}
			</DropdownArea>
		</main>
	);
};

const mapStateToProps = (state) => ({
	user: allUser(state),
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			changeUser,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
