//LIBs
import React, { useEffect, useState } from "react";

//COMPONENTs
import Modal from "../Modal";
import Button from "../Button";
import Input from "../Input";
import TextField from "../TextField";

//HELPERs
import toast from "../../core/helpers/toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// ACTIONs
import { changeUser } from "../../core/reducers/UserReducer/actions";

//API
import { cancelSignature, getCancelStatus } from "../../core/api";

const CancelSignature = (props) => {
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [formCancel, setFormCancel] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelStatus, setCancelStatus] = useState();

    useEffect(() => {
        start();
    }, []);

    const start = async () => {
        const status = await getCancelStatus();
        if (status.error.title) {
            toast("error", status.error.title);
            return;
        }

        setCancelStatus(status.data);
    };

    const confirmCancelModal = () => setConfirmCancel(!confirmCancel);

    const openFormCancelModal = () => {
        setFormCancel(!formCancel);
        setConfirmCancel(false);
    };

    const closeFormCancelModal = () => {
        setFormCancel(!formCancel);
        clear();
    };

    const sendCancel = async () => {
        if (cancelReason.length > 1) {
            const formData = new FormData();
            formData.append("reason", cancelReason);

            const req = await cancelSignature(formData);

            if (req.error.title) {
                toast("error", req.error.title);
            } else {
                toast("success", req.data.title);
                setTimeout(() => {
                    toast("info", req.data.message);
                }, 800);
            }

            clear();
        } else {
            toast("warning", "Insira o motivo do cancelamento.");
        }
    };

    const clear = () => {
        setConfirmCancel(false);
        setFormCancel(false);
        setCancelReason("");
    };

    const cancelCancelSignature = () => {
        toast("success", "Solicitação de cancelamento removida!");
        clear();
    };

    return (
        <div className="CalcelSignature">
            {props.type === "status" && cancelStatus ? (
                <button onClick={()=>props.clickable && confirmCancelModal}>
                    <TextField
                        label={`Cancelamento:`}
                        value={`${!!cancelStatus.is_active ? "Cancelado" : "Em andamento..."}`}
                    />
                </button>
            ) : (
                <button className="cancel-plan" onClick={confirmCancelModal}>
                    Cancelar Assinatura
                </button>
            )}
            <Modal
                title={cancelStatus ? "Status do Cancelamento" : "Cancelar Assinatura"}
                visible={confirmCancel}
                onHide={confirmCancelModal}
            >
                {cancelStatus ? (
                    <div className="modal-cancel-status">
                        <p>
                            Pedido feito por: <span>{cancelStatus.user_name}</span>
                        </p>
                        <p>
                            Motivo do cancelamento: <span>{cancelStatus.reason}</span>
                        </p>
                        <p>
                            Data da solicitação:{" "}
                            <span>
                                {new Date(cancelStatus.created_at).toLocaleString("pt-BR", {
                                    timeZone: "UTC",
                                })}
                            </span>
                        </p>
                        {cancelStatus.date_to_cancel && (
                            <p>
                                Data do cancelamento: <span>{cancelStatus.date_to_cancel}</span>
                            </p>
                        )}
                        <p>
                            Status do cancelamento:{" "}
                            {!!cancelStatus.is_active ? (
                                <span className="canceled">Cancelado</span>
                            ) : (
                                <span className="inProgress">Em andamento...</span>
                            )}
                        </p>
                        <div className="buttons-holder">
                            <Button type="button" onClick={cancelCancelSignature}>
                                Cancelar solicitação
                            </Button>
                            <Button type="button" border="outlined" onClick={confirmCancelModal}>
                                Voltar
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="modal-cancel-content">
                        <p>Você deseja cancelar sua assinatura?</p>
                        <div className="buttons-holder">
                            <Button type="button" border="outlined" onClick={openFormCancelModal}>
                                Sim
                            </Button>
                            <Button type="button" onClick={confirmCancelModal}>
                                Não
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>

            <Modal title="Cancelar Assinatura" visible={formCancel} onHide={closeFormCancelModal}>
                <div className="modal-cancel-content">
                    <p>Qual o motivo do cancelamento?</p>
                    <Input
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        type="text"
                        placeholder="Descreva o motivo do cancelamento"
                    />
                    <div className="buttons-holder">
                        <Button type="button" border="outlined" onClick={sendCancel}>
                            Cancelar assinatura
                        </Button>
                        <Button type="button" onClick={closeFormCancelModal}>
                            Voltar
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeUser,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(CancelSignature);
