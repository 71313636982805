// LIBs
import React from "react";
import { Link } from "react-router-dom";

// HELPERs
import navItemActive from "../../core/helpers/navItemActive";

export default props => (
  <li className="NavItem" onClick={navItemActive}>
    <Link reloadDocument to={props.to || ""}>
      <span>{props.icon}</span>
      <p>{props.label}</p>
    </Link>
  </li>
);