// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import Webstores from "../../containers/Webstores";

// ACTIONs
import { changeUser } from "../../core/reducers/UserReducer/actions";

// HELPERs
import { validate } from "../../core/helpers/validate";

// REDUCERs
import { allUser } from "../../core/reducers/UserReducer/selectors";

const WebstoresView = (props) => {
  const navigate = useNavigate();
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);
  const [ limit, setLimit ] = useState(1);

  useEffect(() => {
    props.onShownLoad()
    validate(navigate);
    getUser();
  }, []);

  async function getUser() {
    if (props.user && props.user.plan) {
      setLimit(props.user.plan.type_customer ? "Ilimitado" : props.user.plan.webstores_quantity);
    }
    props.onHideLoad();
  }

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  return (
    <main className="WebstoresView">
      {props.user && 
      <DropdownArea
        label={props.user.user_id === null ? `Webstores / Limite: ${`${limit}`}` : "Webstores" }
        id="webstores" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="WebstoresView__blocks"
      >
        <Webstores showForm={props.user.user_id === null} onRequest={() => toggleDropdown(activeId)} user={props.user} />
      </DropdownArea>}
    </main>
  );
}

const mapStateToProps = (state) => ({
  user: allUser(state),
});

export default connect(mapStateToProps)(WebstoresView);