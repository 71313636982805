// LIBs
import React, { useState } from "react";

// COMPONENTs
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";

const FormPagesPages = (props) => {
  const [ title, setTitle ] = useState("Loja");
  const [ identification, setIdentification ] = useState("menu-loja");
  const [ urlKey, setUrlKey ] = useState("loja");
  const [ status, setStatus ] = useState("Ativo");

  return (
    <div className="FormPagesPages">
      <div className="FormPagesPages__inputs">
        <div className="FormPagesPages__inputs--duo">
          <Input 
            type="text"
            className="InputDuo"
            label="Titulo:"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <Input 
            type="text"
            className="InputDuo"
            label="Identificação:"
            value={identification}
            onChange={(e) => setIdentification(e.target.value)}
          />
        </div>

        <div className="FormPagesPages__inputs--duo">
          <Select
            label="status"
            className="SelectDuo"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option>Ativo</option>
            <option>Inativo</option>
          </Select>
        </div>
      </div>

      <div className="FormPagesPages__content">
        {props.children}
      </div>

      <div className="FormPagesPages__button">
        <Button type="button">Salvar</Button>
      </div>
    </div>
  );
}

export default FormPagesPages;