// LIBs
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// API
import {
  allBlocks,
  delBlock,
  allContentBlock,
  addContentBlock,
} from "../../core/api";

// HELPERs
import toast from "../../core/helpers/toast";
import { validate } from "../../core/helpers/validate";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";
import Table from "../../components/Table";

// FORMs
import FormBlocksBlock from "../../Forms/Blocks/Block";
import FormBlocksContent from "../../Forms/Blocks/Content";

// REDUCERs
import { planUser } from "../../core/reducers/UserReducer/selectors";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Título", name: "title" },
  { label: "Menu", name: "menu_name" },
  { label: "Webstore", name: "webstore_name" },
];

const inputs = [
  {
    type: "number",
    value: 2,
    name: "number",
    placeholder: "Digite um número",
  },
  {
    type: "text",
    value: "Meu primeiro texto",
    name: "text",
    placeholder: "Digite um Texto",
  },
  {
    type: "color",
    value: "#000000",
    name: "color",
    placeholder: "Digite um Texto",
  },
  {
    type: "date",
    value: `2023-01-01`,
    name: "date",
    placeholder: "Digite uma Data",
  },
  {
    type: "email",
    value: "admin@admin.com",
    name: "email",
    placeholder: "Digite um e-mail",
  },
  {
    type: "checkbox",
    value: true,
    name: "checkbox",
    placeholder: "Selecione"
  },
  {
    type: "file",
    value: "",
    name: "file",
    placeholder: "Selecione uma Imagem",
    file: null
  },
  {
    type: "textarea",
    value: "",
    name: "textarea",
    placeholder: "Escreva seu texto",
    editor: false,
  },
  {
    type: "files",
    value: "",
    name: "files",
    placeholder: "Salve várias imagens",
    file: []
  },
  {
    type: "texts",
    value: "",
    name: "texts",
    placeholder: "Grupo de Texto",
    values: [""],
  }
];

const INITIAL_BLOCK = {
  list: [],
  count_pages: 1,
  current_page: 1,
};

const BlocksView = (props) => {
  const navigate = useNavigate();
  const [ blocks, setBlocks ] = useState(INITIAL_BLOCK);
  const [ block, setBlock ] = useState({});
  const [ showInputsBlocks, setShowInputsBlocks ] = useState({});
  const [ contentBlock, setContentBlock ] = useState([]);
  const [ inputBlockId, setInputBlockId ] = useState(null);
  const [ inputBlock, setInputBlock ] = useState(null);
  const [ activeId, setActiveId ] = useState("");
  const [ planStatus, setPlanStatus ] = useState(true);
  const [ pagePerView, setPagePerView ] = useState(3);
  const [ orderTableBy, setOrderTableBy ] = useState("id");
  const [ webstore, setWebstore ] = useState(0);
  const [ order, setOrder ] = useState("asc");
  
  useEffect(() => {
    const webstoreValue = +props.webstore.value
    setWebstore(webstoreValue)
    listBlocks(1, webstoreValue);
  }, [pagePerView, orderTableBy, order, props.webstore.value])

  useEffect(() => {
    props.onShownLoad();
    validate(navigate);
  }, [])

  useEffect(() => {
    listContentBlock();
  }, [showInputsBlocks])

  useEffect(() => {
    toggleDropdown(activeId);
  }, [blocks])

  useEffect(() => {
    toggleDropdown("inputs-blocks");
  }, [contentBlock, inputBlock])

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if (id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setActiveId(id);
      }
    } else {
      setActiveId("");
    }
  }

  function switchOrder (){
    setOrder(prev=> prev === "asc" ? "desc" : "asc")
  }

  async function listBlocks(page = 1, selectedWebstore = webstore) {
    const req = await allBlocks({
      page:page, 
      limit:pagePerView, 
      orderby:orderTableBy,
      order:order,
      webstore_id: selectedWebstore 
    });

    if (req.data && req.data.list && req.data.list.length > 0) {
      setBlocks(req.data);
    } else {
      setBlocks(INITIAL_BLOCK);
    }

    if (props.plan) {
      const price = props.plan.price;
      const planStatus = price > 0;
      setPlanStatus(planStatus);
    }
    props.onHideLoad()
  }

  async function listContentBlock() {
    if (showInputsBlocks.id) {
      const req = await allContentBlock(showInputsBlocks.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data && req.data.length > 0) {
        setContentBlock(req.data[0].items);
      } else {
        setContentBlock([]);
      }
    }
  }

  function editBlock(block) {
    setBlock(block);
    setShowInputsBlocks({});
  }

  async function deleteBlock(block) {
    if (block.id) {
      const req = await delBlock(block.id);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
        return;
      }

      if (blocks.list.length > 1) {
        listBlocks(blocks.current_page);
      } else {
        listBlocks();
      }

      toast("success", "Deletado com sucesso!");
      setBlock({});
    }
  }

  function viewBlock(block) {
    setBlock({});
    setShowInputsBlocks(block);
  }

  function beforeAddInput(event) {
    const type = event.target.value;
    const input = inputs.find(input => input.type === type);

    if (input) {
      setInputBlock(input);
    } else {
      setInputBlock(null);
    }
  }

  function editPropertieInput(e, propertie) {
    const newInputBlock = { ...inputBlock };
    const value = e.target.value;

    newInputBlock[propertie] = valuePropertie(value, newInputBlock[propertie]);

    setInputBlock(newInputBlock);
  }

  function valuePropertie(text, value) {
    if (typeof value === "number") {
      if (text === "") {
        return 0;
      } else {
        return parseInt(text);
      }
    } else {
      return text;
    }
  }

  function addInput() {
    const newContentBlock = [...contentBlock];

    if (inputBlock) {
      if (newContentBlock.length === 0) {
        newContentBlock.push([inputBlock]);
      } else if (newContentBlock.length === 1) {
        if (!newContentBlock[0].find(contentItem => contentItem.name === inputBlock.name)) {
          let arr = [...newContentBlock[0]];
          arr.push(inputBlock);
          newContentBlock[0] = arr;
        } else {
          toast("warning", "Já tem um campo com este nome!");
          return;
        }
      } else if (newContentBlock.length > 1) {
        newContentBlock.forEach((content, index) => {
          let input = { ...inputBlock };
          input.name = `${input.name}${index === 0 ? "" : `_${index}`}`;
          content.push(input);
        });
      }

      if (planStatus) {
        toast("success", "Registrado com sucesso!");
        send(newContentBlock);
        setContentBlock(newContentBlock);
        setInputBlock(null);
      } else {
        if (inputBlock.type == "textarea") {
          if (newContentBlock[0].length === 1) {
            toast("success", "Registrado com sucesso!");
            send(newContentBlock);
            setContentBlock(newContentBlock);
            setInputBlock(null);
          } else {
            toast("warning", "Seu plano é gratuito e não permite fazer mais um registro!");
          }
        } else {
          toast("warning", "Seu plano é gratuito e não permite registrar campo que não seja do tipo Área de Texto!");
        }
      }
    }
  }

  function editInput(contentItemId) {
    setInputBlockId(contentItemId);
    setInputBlock(contentBlock[0][contentItemId]);
  }

  function delInput(contentItemId) {
    const newContentBlock = [...contentBlock];

    newContentBlock.forEach((inputs, index) => {
      newContentBlock[index] = inputs.filter((input, key) => key !== contentItemId);
    });

    send(newContentBlock);
    setContentBlock(newContentBlock);
  }

  function sendEdit() {
    const newContentBlock = [...contentBlock];

    newContentBlock.forEach((inputs, index) => {
      const newInputBlock = { ...inputBlock };

      if (index >= 1) {
        newInputBlock.name = `${newInputBlock.name}_${index}`;
      }

      inputs[inputBlockId] = newInputBlock;
    });

    toast("success", "Atualizado com sucesso!");
    send(contentBlock);
    setContentBlock(newContentBlock);
  }

  async function send(content) {
    if (block.id || showInputsBlocks.id) {
      const req = await addContentBlock(block.id || showInputsBlocks.id, { items: content });

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (inputBlockId !== null) {
        setInputBlockId(null);
        setInputBlock(null);
      }
    }
  }

  function clear() {
    setBlock({});
    setShowInputsBlocks({});
    setContentBlock([]);
    setInputBlock(null);
  }

  return (
    <main className="BlocksView">
      <DropdownArea
        label="Blocos"
        id="groups"
        activeId={activeId}
        onShow={isDropdownArea}
        className="BlocksView__blocks"
      >
        <FormBlocksBlock
          data={block}
          onRequest={() => listBlocks(blocks.current_page)}
          onClear={clear}
          blocks={blocks}
        />
        <Table
          onFilter={(filter)=>{
            if (filter === orderTableBy) {
              switchOrder();
            } else {
              setOrder("asc");
              setOrderTableBy(filter);
            }
          }}
          filter={orderTableBy}
          order={order}
          setPageQty={(e)=>{setPagePerView(+e)}}
          collumns={collumns}
          rows={blocks.list}
          viewLabel="Adicionar Campo"
          navigation={true}
          countPages={blocks.count_pages}
          currentPage={blocks.current_page}
          onView={viewBlock}
          onEdit={editBlock}
          onDel={deleteBlock}
          onPage={(page) => listBlocks(page)}
        />
      </DropdownArea>

      {showInputsBlocks.id &&
        <DropdownArea
          label="Campos do Bloco"
          id="inputs-blocks"
          activeId={activeId}
          onShow={isDropdownArea}
          className="BlocksView__inputsBlocks"
        >
          <FormBlocksContent
            title={showInputsBlocks.title}
            data={contentBlock}
            inputSelected={inputBlock ? inputBlock : ""}
            inputId={inputBlockId}
            onChange={beforeAddInput}
            onChangePropertie={editPropertieInput}
            onCancel={() => setInputBlock(null)}
            onAddInput={addInput}
            onEditInput={sendEdit}
            onEdit={editInput}
            onDel={delInput}
          />
        </DropdownArea>
      }
    </main>
  );
}

const mapStateToProps = state => ({
  webstore:state.webstore,
  plan: planUser(state),
});

export default connect(mapStateToProps)(BlocksView);
