// HELPERs
import cn from "../../core/helpers/cn";

// ICONs
import { MdClose } from "react-icons/md";

export default props => {
  let modalClass = "Modal";

  if (props.visible) modalClass += " active-modal";

  function onBackgroundHide(e) {
    if (e.target.classList.contains("Modal")) {
      props.onHide();
    }
  }

  return (
    <div className={cn(modalClass, props.className)} onClick={onBackgroundHide}>
      <div className="Modal__content">
        <div className="Modal__content--title">
          <h3>{props.title}</h3>

          <div className="Close" onClick={props.onHide}>
            <span>
              <MdClose />
            </span>
          </div>
        </div>

        <div className="Modal__content--body">
          {props.children}
        </div>
      </div>
    </div>
  );
}