// LIBs
import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import CodeEditor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

// HELPERs
import cn from "../../core/helpers/cn";

// ICONS
import { HiCode, HiMenuAlt2 } from "react-icons/hi";

const jsBeautify = require('js-beautify').html;

export default props => {
  const [ formated, setFormated ] = useState(true);

  useEffect(() => {
    setFormated(true);
  }, [])
  
  let buttonsClass = "EditorWorld__buttons";
  
  if (props.editor) buttonsClass += " active-editor" 
  
  const formattedCode = jsBeautify(props.value, { indent_size: 4 });

  return (
    <div className={cn("EditorWorld", props.className)}>
      <div className={buttonsClass}>
        <button onClick={props.onToggle}>
          { props.editor ? <HiMenuAlt2 /> : <HiCode /> }
        </button>
      </div>

      { props.editor ?
          <CodeEditor 
            className="EditorWorld__code"
            placeholder={props.placeholder}
            value={formated ? formattedCode : props.value}
            onValueChange={code => {
              props.onChange(code);
              setFormated(false);
            }}
            highlight={code => highlight(code, languages.text)}
            padding={25}
          />
        :
          <Editor 
            apiKey="API_KEY"
            value={props.value}
            onEditorChange={props.onChange}
          />
      }
    </div>
  );
}