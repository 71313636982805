// LIBs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// HELPERs
import toast from "../../../core/helpers/toast";

// COMPONENTs
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";

// ICONs
import { FiCheckCircle } from "react-icons/fi";

// CONFIG
import { getGroups, addUser, updateUser, getWebStoresAll } from '../../../core/api';

// REDUCERs
import { changeListWebstore } from "../../../core/reducers/WebstoreReducer/actions";
import { listWebstore } from "../../../core/reducers/WebstoreReducer/selectors";

const FormUserUser = (props) => {
  let userButtonClass = "FormUserUser__button";

  if (props.data.id) userButtonClass += " grid-button";

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [group, setGroup] = useState("");
  const [status, setStatus] = useState(1);
  const [groups, setGroups] = useState([]);
  const [dataWebStore, setDataWebStore] = useState([]);
  const [webStoreAll, setWebStoreAll] = useState([]);
  const [userCurrent, setUserCurrent] = useState([]);
  const [userWebstoresView, setUserWebstoresView] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (props.data.id) {
      setFirstname(props.data.firstname);
      setLastname(props.data.lastname);
      setEmail(props.data.email);
      setGroup(props.data.group_id);
      setStatus(props.data.is_active);
      setUserWebstoresView(props.data.users_webstores_view);
      setDataWebStore(userWebstoreViewOpts(props.data.users_webstores_view))
    }
  }, [props.data])

  async function getList() {
    const currUser = props.userCurrent;
    
    if( currUser.user_id === null){
      let req = await getGroups({});
        if (req.data.list) {
          setGroups(req.data.list);
        }
    }else{
      setGroups([currUser.group])
    }

    let data = props.userCurrent;
    setUserCurrent(data);

    if (props.list.length > 0) {
      props.changeWebstore(`${props.list[0].id}`)
    }
  }

  function clear() {
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
    setGroup("");
    setStatus(1);
    setDataWebStore([])
    setUserWebstoresView([])
    props.setUser([]);
  }

  function send() {
    if (props.data.id) {
      update();
    } else {
      register();
    }
  }

  async function register() {
    if (firstname && lastname && email && password && (group && group !== "null")) {
      let params = {
        firstname,
        lastname,
        email,
        password,
        is_active: status,
        group_id: group,
        users_webstores_view: dataWebStore.length ? dataWebStore : ["all"]
      };

      const req = await addUser(params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data) {
        toast("success", req.data.title);
      }

      clear();
      props.onRequest();
    } else {
      toast("warning", "Preencha todas as informações corretamente!");
    }
  }

  async function update() {
    if (firstname && lastname && (group && group !== "null")) {

      if (password) {
        var params = { firstname, lastname, is_active: status, group_id: group, password, users_webstores_view: dataWebStore.length ? dataWebStore : ["all"] };
      } else {
        var params = { firstname, lastname, is_active: status, group_id: group, users_webstores_view: dataWebStore.length ? dataWebStore : ["all"] };
      }

      const req = await updateUser(props.data.id, params);

      if (req.error && req.error.title) {
        toast("error", req.error.title);
      }

      if (req.data) {
        toast("success", req.data.title);
      }

      clear();
      props.changeListWebstore();
      props.onRequest();
    } else {
      toast("warning", "Preencha todas as informações corretamente!");
    }
  }

  const handleButtonClick = () => {
    setDataWebStore([]);
  };

  const handleOptionClick = (event) => {
    const optionValue = event.target.value;
    if (event.target.selected) {
      setDataWebStore(prev=>prev.filter((value) => value !== optionValue));
    } else {
      setDataWebStore(prev=>[...prev, optionValue]);
    }
  };

 const userWebstoreViewOpts = (webstoreView) =>{
  return webstoreView.map(wsView=>`${wsView.webstore.id}`);
 }

  return (
    <div className="FormUserUser">
      <div className="FormUserUser__inputs">
        <Grid>
          <Input
            label="Nome: *"
            className="InputDuo"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <Input
            label="Sobrenome: *"
            className="InputDuo"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <Input
            label="E-mail: *"
            className="InputDuo"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly={!!props.data.id}
          />
          <Select label="Grupo: *" value={group} onChange={(e) => setGroup(e.target.value)}>
            <option value="null">Selecione um grupo</option>
            {groups &&
              groups.map((el, key) => {
                return (<option className="option" key={key} value={el.id}>{el.name}</option>)
              })
            }
          </Select>
          <Input
            label="Senha: *"
            className="InputDuo"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
          <Select label="Status" value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value={1}>Ativo</option>
            <option value={0}>Inativo</option>
          </Select>
        </Grid>

        {userCurrent && userCurrent.plan && userCurrent.plan.view_webstores !== false && (
          <div className="multiple">
            <label htmlFor="webstores">Selecione uma ou mais Webstores -
              <Button className={userWebstoresView.length ? 'active' : ''} type="button" value={'all'} onClick={handleButtonClick}>
                Todos {dataWebStore.length === 0 && <FiCheckCircle />}
              </Button>
            </label>

            <select id="webstores" value={dataWebStore} onChange={()=>{}} multiple>
              {props.list &&
                props.list.map((webstore) => (
                  <option
                    key={webstore.id}
                    value={webstore.id}
                    onClick={handleOptionClick}
                  >
                    {webstore.name}
                  </option>
                ))
              }
            </select>

          </div>
        )}

      </div>

      <div className={userButtonClass}>
        <Button type="button" onClick={send}>
          {props.data.id ?
            "Salvar"
            :
            "Registrar"
          }
        </Button>
        {props.data.id &&
          <Button
            type="button"
            border="outlined"
            onClick={() => {
              clear();
            }}
          >
            Cancelar
          </Button>
        }
      </div>

      <div className="FormUserUser__content">
        {props.children}
      </div>
    </div >
  );
}

const mapStateToProps = (state) => ({
  list: listWebstore(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeWebstore: (payload)=>{return{type:"webstore/change", payload:payload}},
  changeListWebstore,
 }, dispatch);
 
 export default connect(mapStateToProps, mapDispatchToProps)(FormUserUser);