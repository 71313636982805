// LIBs
import { combineReducers } from "redux";

// REDUCERs
import AppReducer from "./AppReducer";
import UserReducer from "./UserReducer";
import AbasReducer from "./AbasReducer";
import WebstoreReducer from "./WebstoreReducer";

export const reducers = combineReducers({
  app: AppReducer,
  user: UserReducer,
  abas: AbasReducer,
  webstore:WebstoreReducer
});