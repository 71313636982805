// LIBs
import axios from "axios";

// CONFIG
import config from "../../core/config/app";

const Api = axios.create({
    baseURL: config.endpoint,
});

const ApiAuth = axios.create({
    baseURL: config.endpoint_auth,
});

const defaultPagination = { page: 1, limit: 3, orderby: "id" }

const filterObjectParams = (filter) => !!filter ? Object.keys(filter).map(key=>(`${key}=${filter[key]}`)).join('&') : ""

export const allConfig = async () => {
    try {
        const req = await Api.get(`config/all`);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
}

export const addContentBlock = async (id, params, isContentBlock) => {
    const token = window.localStorage.getItem("token") || null;

    const newParams = { ...params };

    if (!isContentBlock) {
        newParams.block_id = id;
        newParams.type = 1;
    }

    if (token) {
        try {
            const req = await ApiAuth.put(`block/content/${id}`, params,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const allContentBlock = async (id) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`block/content/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const delBlock = async (id) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`blocks/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const editBlock = async (id, params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`blocks/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const addBlock = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("blocks", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const allBlocks = async (filter = defaultPagination) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`blocks?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updateUser = async (id, params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`users/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const addUser = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("users", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const allUsers = async (filter = defaultPagination) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`users/list?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const addUsersMail = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("users/mail", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}


/**
 * 
 * @returns 
 */
export const getAllUsers = async () => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`users/list/all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const cancelSignature = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("users/cancel", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getCancelStatus = async () => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get("users/cancel", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const allMailMarketing = async (filter = defaultPagination) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`emailmarketing?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const addMailMarketing = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("emailmarketing", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updateMailMarketing = async (id, params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`emailmarketing/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const deleteMailMarketing = async (id) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`emailmarketing/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const uploadMarketing = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`emailmarketing/inex/import`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const exportMarketing = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`emailmarketing/inex/export`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updatePJ = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put("users/pj/update", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getPJ = async () => {
    const token = window.localStorage.getItem("token") || null;


    if (token) {
        try {
            const req = await ApiAuth.get("users/pj", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * register address
 * @param {*} params 
 * @returns 
 */
export const registerAddress = async (params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("users/address", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updateAddress = async (id, params) => {
    const token = window.localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`users/address/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getAddress = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get("users/address/find", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * get zipcode data
 */
export const getZipCodeData = async (zipcode) => {
    try {
        const req = await Api.get(`viacep/${zipcode}`);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * create account user master
 * @param {*} params 
 * @returns 
 */
export const createAccount = async (params) => {
    try {
        const req = await Api.post("account/register", params);

        if (req.data.data) {
            return req.data;
        }
        if (req.data.error) {
            return req.data.error;
        }
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * process login users
 * @param {*} params 
 * @returns 
 */
export const Login = async (params) => {
    try {
        const req = await Api.post("auth", params);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * forget user
 * @param {*} params 
 * @returns 
 */
export const setForget = async (params) => {
    try {
        const req = await Api.post("account/forget", params);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * account recover user
 * @param {*} params 
 * @returns 
 */
export const getRecoverValidateToken = async (token) => {
    try {
        const req = await Api.get(`account/validate/${token}`);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * account recover user
 * @param {*} params 
 * @returns 
 */
export const setRecover = async (token, params) => {
    try {
        const req = await Api.post(`account/recover/${token}`, params);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * return token create in createAccount
 * @returns 
 */
export const getToken = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get("token", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * return token new in createAccount
 * @returns 
 */
export const updateToken = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put("token", null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * get data user logged
 * @returns 
 */
export const getUser = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get("users", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            localStorage.removeItem('token');
            window.location.href = "account";
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}
/**
 * update user
 * @param {*} header 
 * @returns 
 */
export const getUserUpdate = async (params) => {
    const token = localStorage.getItem("token") || null;

    try {
        const req = await ApiAuth.put("users", params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
}

/**
 * logout user
 * @param {*} header 
 * @returns 
 */
export const logOut = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await Api.get("auth/logout", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * abas user
 * @returns 
 */
export const getAbas = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get("abas", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * abas api
 * @returns 
 */
export const getAbasApi = async (filter = defaultPagination) => {
    try {

        const req = await Api.get(`abas-api?${filterObjectParams(filter)}`);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
}

/**
 * groups user
 * @returns 
 */
export const getGroups = async (filter = defaultPagination) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`groups?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getGroupsAbas = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`groups/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const registerGroup = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("groups", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updateGroup = async (id, params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`groups/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const deleteGroup = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`groups/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * @returns json webstores
 */
export const getWebStoresAll = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`webstores/all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getWebStores = async (filter = defaultPagination) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`webstores?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const registerWebStore = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post("webstores", params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updateWebStore = async (id, params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`webstores/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const deleteWebStore = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`webstores/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getMenus = async (filter) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`menus?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const registerMenus = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`menus`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const editMenus = async (id, params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`menus/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const deleteMenus = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`menus/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * Gallery
 */
export const getGalleryAll = async () => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`gallery/all`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * Gallery
 */
export const getGallery = async (filter = defaultPagination) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`gallery?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getGalleryWebstore = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`gallery/webstore/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const registerGallery = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`gallery`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}


export const updateGallery = async (id, params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`gallery/update/${id}`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}


export const deleteGallery = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`gallery/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}


/**
 * Contact
 */
export const getContactList = async (filter = defaultPagination) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`contact?${filterObjectParams(filter)}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const registerContact = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`contact`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const updateContact = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.put(`contact/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const deleteContact = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.delete(`contact/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * Plans
 */
export const getPlans = async () => {
    try {
        const req = await Api.get(`plans`);

        return req.data;
    } catch (err) {
        console.error(err);
        return [];
    }
}

/**
 * checkout
 */
export const registerCheckout = async (params) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.post(`checkout`, params, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const orderEnableMP = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`checkout/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * orders
 */
export const getOrders = async (mp_status) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`orders?mp_status=${mp_status}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

export const getOrderStatus = async (id) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`orders/status/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}

/**
 * home
 */
export const getHome = async (mp_status) => {
    const token = localStorage.getItem("token") || null;

    if (token) {
        try {
            const req = await ApiAuth.get(`home`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return req.data;
        } catch (err) {
            console.error(err);
            return [];
        }
    } else {
        console.error("Sem token!");
        return [];
    }
}