// COMPONENTs
import Table from "../../../components/Table";

const collumns = [
  { label: "ID", name: "id" },
  { label: "Título", name: "title" },
  { label: "Menu", name: "menu_name" },
  { label: "Webstore", name: "webstore_name" },
];

export default (props) => {
  return (
    <div className="BlocksList">
      <Table 
        onFilter={props.onFilter}
        filter={props.filter}
        order={props.order}
        setPageQty={props.setPageQty}
        collumns={collumns}
        rows={props.blocks.list}
        viewLabel="Exibir"
        navigation={true}
        countPages={props.blocks.count_pages}
        currentPage={props.blocks.current_page}
        onView={props.onView}
        onPage={(page)=>props.onPage(page)}
      />
    </div>
  );
}