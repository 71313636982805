// LIBs
import React from "react";
import { Link } from "react-router-dom";

// HELPERs
import cn from "../../core/helpers/cn";

export default props => {
  const { type = "link" } = props;
  switch(type) {
    case "link":
      return (
        <Link
          className={cn("ButtonLink", props.className)}
          to={props.to || "/"}
          {...props.link}
        >
          {props.children}
        </Link>
      );
    case "button":
      let buttonClass = "Button";

      if (props.border === "outlined") buttonClass += " Button--outlined"

      return (
        <button
          className={cn(buttonClass, props.className)}
          onClick={props.onClick}
          {...props.button}
        >
          {props.children}
        </button>
      );
    case "external":
      return (
        <a 
          href={props.href || "#"}
          className={cn("ButtonExternal", props.className)}
          {...props.external}
        >
          {props.children}
        </a>
      );
    default:
      return null;
  }
}