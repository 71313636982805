const user = state => state.user;
export const idUser = state => user(state).id;
export const cpfUser = state => user(state).cpf;
export const groupIdUser = state => user(state).group_id;
export const groupUser = state => user(state).group;
export const planIdUser = state => user(state).plan_id;
export const planUser = state => user(state).plan;
export const userIdUser = state => user(state).user_id;
export const rgUser = state => user(state).rg;
export const firstNameUser = state => user(state).firstname;
export const lastNameUser = state => user(state).lastname;
export const periodUser = state => user(state).period;
export const folderUser = state => user(state).folder;
export const phoneUser = state => user(state).phone;
export const emailUser = state => user(state).email;
export const emailVerifiedAtUser = state => user(state).email_verified_at;
export const stateTaxUser = state => user(state).state_tax;
export const validateUser = state => user(state).validate;
export const documentUser = state => user(state).document;
export const isActiveUser = state => user(state).is_active;
export const usersWebstoresViewUser = state => user(state).users_webstores_view;
export const typeUser = state => user(state).type;
export const tokenUser = state => user(state).token;
export const createdAtUser = state => user(state).created_at;
export const updatedAtUser = state => user(state).updated_at;
export const allUser = state => user(state);