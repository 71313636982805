//LIBs
import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//COMPONENTs
import Modal from "../../../components/Modal";
import PlanCard from "../../../components/PlanCard";
import Arrow from "../../../components/Arrow";
import { useRef } from "react";

const plansSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  dotsClass: "Slider-Dots",
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

const modalSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  dotsClass: "Slider-Dots",
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

const PlansMainPage = ({
  plans,
  modalOpen,
  setModalOpen,
  selectedPlan,
  setSelectedPlan,
  setStep,
  setSelectedPeriod,
}) => {
  const plansSlider = useRef();
  const modalSlider = useRef();

  const calculateCardHeight = () => {
    const advantageQty = Math.max(
      ...plans.map(
        (obj) => obj.json_items.filter((item) => item.is_active).length
      )
    );
    return 240 + advantageQty * 65;
  };

  function next(sliderRef) {
    sliderRef.current.slickNext();
  }

  function previous(sliderRef) {
    sliderRef.current.slickPrev();
  }

  return (
    <>
      <div className="plans-main-area">
        <Arrow onClick={() => next(plansSlider)} responsive />
        <Arrow onClick={() => previous(plansSlider)} type="left" responsive />
        {plans &&
          <Slider ref={plansSlider} {...plansSettings}>
              {plans.sort((a, b) => a.price - b.price).map((plan) => (
                <div key={plan.id} className="plans-card-holder">
                  <PlanCard
                    style={{ height: calculateCardHeight() }}
                    btnLabel={"SELECIONAR PLANO"}
                    plan={plan}
                    setModalOpen={setModalOpen}
                    setSelectedPlan={setSelectedPlan}
                    onClick={() => {
                      setSelectedPlan(plan);
                      if (plan.price === 0) {
                        setSelectedPeriod({period: "yearly", price: 0, validate: 12});
                        setStep("register");
                      } else {
                        setModalOpen(true);
                      }
                    }}
                  />
                </div>
              ))}
          </Slider>
        }
      </div>
      <Modal
        className="plans-modal"
        title={`Selecionar período - Plano: ${selectedPlan.name}`.toUpperCase()}
        visible={modalOpen}
        onHide={() => setModalOpen(false)}
      >
        <div className="periods-modal">
          <Arrow onClick={() => next(modalSlider)} />
          <Arrow onClick={() => previous(modalSlider)} type="left" />
          {selectedPlan.periods && 
          <Slider ref={modalSlider} {...modalSettings}>
            {selectedPlan.periods.map((period, index) => (
              <div key={index} className="plans-card-holder">
                <PlanCard
                  onClick={() => {
                    setSelectedPeriod(period);
                    setStep("register");
                  }}
                  btnLabel={"SELECIONAR PERIODO"}
                  plan={{
                    name:
                      period.period === "monthly"
                        ? "Mensal"
                        : period.period === "quarterly"
                        ? "Trimestral"
                        : period.period === "semi_annual"
                        ? "Semestral"
                        : "Anual",
                    price: period.price,
                  }}
                />
              </div>
            ))}
          </Slider>}
        </div>
      </Modal>
    </>
  );
};

export default PlansMainPage;
