// LIBs
import React from "react";

// COMPONENTs
import TextField from "../../../components/TextField";

// FORM
import FormHomeAddress from "../../../Forms/Home/Address";

export default (props) => (
  <div className="Address">
    <div className="Address__info">
      { props.data &&
        <>
          <TextField label="Endereço:" value={props.data.district} />
          <TextField label="Bairro:" value={props.data.street} />
          <TextField label="Complemento:" value={props.data.complement || ""} />
          <TextField label="Cidade:" value={props.data.city} />
          <TextField label="Estado:" value={props.data.region} />
          <TextField label="Cep:" value={props.data.zipcode} />
        </>
      }
      {/* <button>Editar</button> */}
    </div>

    <div className="Address__form">
      <FormHomeAddress data={props.data} onLoad={props.onRequest} />
    </div>
  </div>
);