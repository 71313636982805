// LIBs
import React, { useState } from "react";

// COMPONENTs
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import Table from "../../../components/Table";

const collumns = [
  { label: "Tipo", name: "type" },
  { label: "Nome do Campo", name: "name", minCarac: 10 },
  { label: "Label", name: "placeholder", minCarac: 10 },
];

const propertiesNoEdit = ["type", "value", "file", "editor", "loading", "values"];

const propertiesEdit = {
  name: "Nome do Campo:",
  placeholder: "Label do Campo:",
  tabSize: "Espaçamento com Tab:"
};

const FormBlocksContent = (props) => {
  const keysInputs = props.inputSelected ? Object.keys(props.inputSelected) : [];

  return (
    <div className="FormBlocksContent">
      <div className="FormBlocksContent__header">
        <h2>Bloco: {props.title}</h2>
      </div>

      <div className="FormBlocksContent__inputsProps">
        <Select 
          label="Tipo do Campo: *" 
          value={props.inputSelected.type || ""} 
          onChange={props.onChange}
        >
          <option>Selecione o tipo do campo</option>
          <option value="number">Campo de Número</option>
          <option value="text">Campo de Texto</option>
          <option value="color">Campo de Cor</option>
          <option value="date">Campo de Data</option>
          <option value="email">Campo de E-mail</option>
          <option value="file">Campo de Imagem</option>
          <option value="textarea">Área de Texto</option>
          <option value="files">Grupo de Imagens</option>
          <option value="texts">Lista</option>
        </Select>

        { (keysInputs.length > 0) &&
          keysInputs.map((item, key) => {
            if (!propertiesNoEdit.includes(item)) {
              return (
                <Input 
                  label={propertiesEdit[item]}
                  type={typeof props.inputSelected[item] === "number" ? "number" : "text"}
                  value={props.inputSelected[item].toString()}
                  onChange={(e) => props.onChangePropertie(e, item)}
                  key={key}
                />
              );
            } else {
              return false;
            }
          })
        }
      </div>

      <div className="FormBlocksContent__buttons">
        <Button 
          type="button" 
          onClick={props.inputId !== null ? props.onEditInput : props.onAddInput}
        >
          { props.inputId !== null ?
            "Salvar"
            :
            "Adicionar"
          }
        </Button>

        { props.inputSelected &&
          <Button 
            type="button" 
            border="outlined"
            onClick={props.onCancel}
          >
            Cancelar
          </Button>
        }
      </div>

      <div className="FormBlocksContent__inputs">
        { (props.data && props.data.length > 0) &&
          <Table 
            collumns={collumns}
            rows={props.data[0]}
            onEdit={(row, index) => props.onEdit(index)}
            onDel={(row, index) => props.onDel(index)}
          />
        }
      </div>
    </div>
  );
}

export default FormBlocksContent;