// COMPONENTs
import Input from "../../../components/Input";
import Table from "../../../components/Table";

// FORMs
import FormMailTags from "../../../Forms/MailTags";

// ICONs
import { BsPlus } from "react-icons/bs";

export default props => {
  const columns = [
    { name: "checked", label: "Checado", children: renderChecked },
    { name: "name", label: "Nome", minCarac: 16 },
    { name: "contact", label: "Contatos", children: renderContact },
    { name: "url", label: "Url" },
  ];

  function renderContact(contact, index, row) {
    return (
      <div className="TdContact">
        <p>{contact}</p>

        <button onClick={() => props.onTagContact(index)}>
          <BsPlus />
        </button>
      </div>
    )
  }

  function renderChecked(checked, index) {
    return (
      <Input 
        type="checkbox"
        checked={checked}
        onChange={() => props.onToggleChecked(index)}
      />
    )
  }

  return (
    <div className="MailTags">
      <FormMailTags data={props.tagSelected} onSend={(name, url) => console.log(name, url)} onCancel={() => props.onTagSelected(null)}>
        <Input 
          type="checkbox"
          label="Selecionar Todos"
          checked={props.allChecked}
          onChange={props.onAllChecked}
          className="CheckboxAll"
        />

        <Table 
          collumns={columns}
          rows={props.data}
          onEdit={(row, index) => props.onTagSelected(index)}
          onDel={(row, index) => console.log(index)}
        />
      </FormMailTags>
    </div>
  );
}