// LIBs
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

// CONFIG
import { getAddress, getPJ } from "../../core/api";

// SELECTORs
import { typeUser } from "../../core/reducers/UserReducer/selectors";

// ACTIONs
import { changeUser } from "../../core/reducers/UserReducer/actions";

// COMPONENTs
import DropdownArea from "../../components/DropdownArea";

// CONTAINERs
import Address from "../../containers/Settings/Address";
import PJ from "../../containers/Settings/PJ";

// HELPERs
import { validate } from "../../core/helpers/validate";
import FormSettingsRegister from "../../Forms/Settings/Register";

const SettingsView = (props) => {
  const navigate = useNavigate();
  const [ activeId, setActiveId ] = useState("");
  const [ height, setHeight ] = useState(1);
  const [ address, setAddress ] = useState(null);
  const [ pj, setPj ] = useState(null);

  useEffect(() => {
    props.onShownLoad()
    validate(navigate);
    initilize();
  }, []);

  function initilize() {
    isAddress();
    if (!props.type) {
      isPj();
    }
    props.onHideLoad()
  }

  async function isAddress() {
    const req = await getAddress();

    if (req.data !== null) {
      setAddress(req.data);
    }
  }

  async function isPj() {
    const req = await getPJ();

    if (req.data !== null) {
      const data = req.data;
      if (data.corporate_name && data.fantasy_name && data.document) {
        setPj(req.data);
      }
    }
  }

  function isDropdownArea(id) {
    if (activeId === "") {
      toggleDropdown(id);
    } else {
      if (activeId !== id) {
        toggleDropdown(id);
      } else {
        toggleDropdown();
      }
    }
  }

  function toggleDropdown(id = "") {
    if(id !== "") {
      const element = document.querySelector(`#${id} .DropdownArea__bottom--container`);

      if (element !== null) {
        setHeight(element.clientHeight);
        setActiveId(id);
      }
    } else {
      setActiveId("");
      setHeight(1);
    }
  }

  function callbackAddress() {
    isAddress();
    setTimeout(() => {
      toggleDropdown(activeId);
    }, 500)
  }

  function callbackPJ() {
    isPj();
    props.changeUser();
  }

  return (
    <main className="SettingsView">
      { !props.type &&
        <DropdownArea 
          label="Pessoa Júridica" 
          id="pj" 
          activeId={activeId}
          height={height}
          onShow={isDropdownArea}
          className="SettingsView__blocks"
        >
          <PJ data={pj} onRequest={callbackPJ} />
        </DropdownArea>
      }

      <DropdownArea 
        label="Endereço" 
        id="my-users-address" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="SettingsView__blocks"
      >
        <Address data={address} onRequest={callbackAddress} />
      </DropdownArea>

      <DropdownArea 
        label="Registrar Mail" 
        id="register-mail" 
        activeId={activeId}
        height={height}
        onShow={isDropdownArea}
        className="SettingsView__blocks"
      >
        <FormSettingsRegister/>
      </DropdownArea>
    </main>
  );
}

const mapStateToProps = state => ({
  type: typeUser(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);