// LIBs
import React from "react";

// FORMs
import FormGroups from "../../Forms/Groups";

export default props => (
  <div className="Groups">
    <FormGroups onLoad={props.onRequest} />
  </div>
);