import React from 'react';

const Loading = () => {

  return (
    <div className="loading-container">
      <div className="loading-dots">
          <div  className="dot-1"></div>
          <div  className="dot-2"></div>
          <div  className="dot-1"></div>
      </div>
    </div>
  );
};

export default Loading;